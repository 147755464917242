module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fully Activated Osteopathy","short_name":"Fully Activated","description":"Fully Activated is an Osteopathic clinic located in Hoppers Crossing in the heart of Melbourne's West. We treat a variety of conditions including low back pain, neck pain, headaches, migraines, shoulder pain and knee pain.","start_url":"/","background_color":"#fff","theme_color":"#43bad8","display":"minimal-ui","icon":"src/images/logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c1ccbbaed8d7795ca4aea9df7e1026bd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KXRWGBF"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
