// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-applied-kinesiology-js": () => import("./../../src/pages/applied-kinesiology.js" /* webpackChunkName: "component---src-pages-applied-kinesiology-js" */),
  "component---src-pages-blog-js": () => import("./../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-osteopathy-js": () => import("./../../src/pages/osteopathy.js" /* webpackChunkName: "component---src-pages-osteopathy-js" */),
  "component---src-pages-services-js": () => import("./../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-thank-you-js": () => import("./../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-blog-post-js": () => import("./../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-service-js": () => import("./../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-tags-js": () => import("./../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

