import React from "react"

export const GolbalStateContext = React.createContext()
export const GlobalStateProvider = props => {
  const [globalState, setGlobalState] = React.useState({
    menuOpen: false,
    enquireOpen: false,
  })
  return (
    <GolbalStateContext.Provider value={[globalState, setGlobalState]}>
      {props.children}
    </GolbalStateContext.Provider>
  )
}
